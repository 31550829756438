<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ item.tracking_id }}
      <v-spacer></v-spacer>
      <template v-if="isShowAction">
        <v-btn color="success" class="mr-2" @click="onAccept">
          {{ $t("labels.accept") }}
        </v-btn>
        <v-btn color="warning" class="mr-2" @click="showDialogReject">
          {{ $t("labels.reject") }}
        </v-btn>
      </template>
      <v-btn color="error" outlined @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        height="calc(100vh - 450px)"
        class="table-padding-2-no-top"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th class="text-center">{{ $t("labels.sku") }}</th>
              <th class="text-center">{{ $t("labels.barcode") }}</th>
              <th class="text-center">{{ $t("labels.image") }}</th>
              <th class="text-center">{{ $t("labels.machine_file") }}</th>
              <th class="text-center">{{ $t("labels.category") }}</th>
              <th class="text-center">{{ $t("labels.name") }}</th>
              <th class="text-center">{{ $t("labels.goods_description") }}</th>
              <th class="text-center">{{ $t("labels.size") }}</th>
              <th class="text-center" style="width: 100px">Y.C Sản xuất</th>
              <th class="text-center" style="width: 75px"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="result-item text-center"
              v-for="item in items"
              :key="`g_${item.id}`"
            >
              <td>{{ item.sku }}</td>
              <td>{{ item.customer_goods_barcode }}</td>
              <td>
                <ImageViewer
                  :url="
                    item.url_images || require('@/assets/common/no-image.jpg')
                  "
                  width="40px"
                  height="40px"
                />
              </td>
              <td>
                <span
                  v-if="item.id_goods_production_record"
                  @click="downloadRecordFile(item)"
                  class="cursor-pointer primary--text text-decoration-underline"
                >
                  {{ $t("labels.download") }}
                </span>
              </td>
              <td>{{ item.category_name }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.size }}</td>
              <td>{{ item.quantity }}</td>
              <td>
                <v-btn x-small color="warning" @click="showDialogReport(item)">
                  Tốc độ vơi
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-dialog v-model="rejectDialog" max-width="360px">
        <RequirementReject
          v-if="rejectDialog"
          :item="item"
          @close="hideDialogReject"
          @confirm="onReject"
        />
      </v-dialog>

      <v-dialog v-model="reportDialog" max-width="800px">
        <ReportForecast
          v-if="reportDialog"
          :sku="reportItem.sku"
          @close="hideDialogReport"
        />
      </v-dialog>
    </v-card-text>

    <v-card-text>
      <RequirementContent :item="item" />
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "RequirementDetail",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
    RequirementContent: () =>
      import("@/components/goods_production/RequirementContent"),
    RequirementReject: () =>
      import("@/components/goods_production/RequirementReject"),
    ReportForecast: () => import("@/components/goods/ReportForecast"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    files: null,
    isLoading: false,
    rejectDialog: false,
    reportItem: {},
    reportDialog: false,
    items: [],
  }),
  computed: {
    isShowAction() {
      if (this.item.status != 2) {
        return false;
      }
      const identityId = window.me.identity_id;
      return this.item.id_identity_confirm === identityId;
    },
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    close() {
      this.$emit("close", true);
    },
    showDialogReport(item) {
      this.reportItem = { ...item };
      this.reportDialog = true;
    },
    hideDialogReport() {
      this.reportItem = {};
      this.reportDialog = false;
    },
    showDialogReject(item) {
      this.reportItem = { ...item };
      this.rejectDialog = true;
    },
    hideDialogReject() {
      this.reportItem = {};
      this.rejectDialog = false;
    },
    getList: debounce(function () {
      httpClient
        .post("/goods-production-requirement-detail", {
          id_goods_production_requirement: this.item.id,
        })
        .then(({ data }) => {
          this.items = [...data];
        });
    }, 500),
    onReject(note) {
      this.updateStatus("reject", note);
    },
    onAccept() {
      if (
        !confirm(
          this.$t(`messages.goods_production_requirement_accept`, {
            tracking: this.item.tracking_id,
          })
        )
      ) {
        return false;
      }
      this.updateStatus("accept", null);
    },
    async downloadRecordFile(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = `${item.goods_production_record_tracking_id}.zip`;

      try {
        await this.downloadExcelFile(
          "/download-goods-production-record-file",
          {
            id_goods_production_record: item.id_goods_production_record,
          },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async updateStatus(type, note) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/goods-production-requirement-confirm", {
          id_goods_production_requirement: this.item.id,
          type,
          note: note || null,
        });
        this.isLoading = false;
        const msg = this.$t("messages.update_success");
        this.$vToastify.success(msg);
        this.$emit("refreshData", true);
        this.close();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
